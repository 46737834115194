$cdnUrl: 'https://cdn.harisenin.net';
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "animate";
@import "custom";
@import "popover";
@import "typography";
@import "vendor/phone-input";
@import "misc/checkbox";
@import "misc/phone_input";
@import "misc/radio";

@import url("https://fonts.googleapis.com/css?family=DM+Sans:300,400,500,600,700,800&display=swap");

@layer base {
  h1 {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #222325;
  }

  body {
    position: relative;
    color: #484848;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: theme("colors.green");
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-inline-start: 20px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  input::placeholder,
  textarea::placeholder {
    color: #909090;
  }
}
